/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { OfferSnapshotAllOfSpecialRecurringDate } from './offer-snapshot-all-of-special-recurring-date';

/**
 * 
 * @export
 * @interface OfferSnapshotAllOf
 */
export interface OfferSnapshotAllOf {
    /**
     * 
     * @type {string}
     * @memberof OfferSnapshotAllOf
     */
    'businessEntityId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferSnapshotAllOf
     */
    'businessUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferSnapshotAllOf
     */
    'offerType'?: OfferSnapshotAllOfOfferTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof OfferSnapshotAllOf
     */
    'status'?: OfferSnapshotAllOfStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof OfferSnapshotAllOf
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferSnapshotAllOf
     */
    'endDate'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OfferSnapshotAllOf
     */
    'genders'?: Array<OfferSnapshotAllOfGendersEnum>;
    /**
     * 
     * @type {number}
     * @memberof OfferSnapshotAllOf
     */
    'ageFrom'?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferSnapshotAllOf
     */
    'ageTo'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OfferSnapshotAllOf
     */
    'tierLevelIds'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof OfferSnapshotAllOf
     */
    'generalLimit'?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferSnapshotAllOf
     */
    'personalLimit'?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferSnapshotAllOf
     */
    'repetition'?: OfferSnapshotAllOfRepetitionEnum;
    /**
     * 
     * @type {number}
     * @memberof OfferSnapshotAllOf
     */
    'duration'?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferSnapshotAllOf
     */
    'specialDate'?: string;
    /**
     * 
     * @type {OfferSnapshotAllOfSpecialRecurringDate}
     * @memberof OfferSnapshotAllOf
     */
    'specialRecurringDate'?: OfferSnapshotAllOfSpecialRecurringDate;
    /**
     * 
     * @type {number}
     * @memberof OfferSnapshotAllOf
     */
    'purchasesThreshold'?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferSnapshotAllOf
     */
    'fiatThreshold'?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferSnapshotAllOf
     */
    'pointsThreshold'?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferSnapshotAllOf
     */
    'rewardAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferSnapshotAllOf
     */
    'placeOfVisit'?: OfferSnapshotAllOfPlaceOfVisitEnum;
    /**
     * 
     * @type {number}
     * @memberof OfferSnapshotAllOf
     */
    'multiplier'?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferSnapshotAllOf
     */
    'customTrigger'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferSnapshotAllOf
     */
    'completedWhen'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum OfferSnapshotAllOfOfferTypeEnum {
    FIRST_PURCHASE = 'FIRST_PURCHASE',
    PURCHASES_NUMBER_FOR_TIME_PERIOD = 'PURCHASES_NUMBER_FOR_TIME_PERIOD',
    PURCHASES_AMOUNT_FOR_TIME_PERIOD = 'PURCHASES_AMOUNT_FOR_TIME_PERIOD',
    MONTHLY_PURCHASES = 'MONTHLY_PURCHASES',
    SINGLE_PURCHASE_AMOUNT = 'SINGLE_PURCHASE_AMOUNT',
    NEW_MEMBER = 'NEW_MEMBER',
    CUSTOMER_VISIT = 'CUSTOMER_VISIT',
    CUSTOMER_RETURN_VISIT = 'CUSTOMER_RETURN_VISIT',
    BALANCE_THRESHOLD_REACHED = 'BALANCE_THRESHOLD_REACHED',
    POINTS_AMOUNT_EARNED = 'POINTS_AMOUNT_EARNED',
    MULTIPLIERS = 'MULTIPLIERS',
    SPECIAL_DAYS = 'SPECIAL_DAYS',
    HAPPY_BIRTHDAY = 'HAPPY_BIRTHDAY',
    ANNIVERSARY = 'ANNIVERSARY',
    CUSTOM = 'CUSTOM'
}
/**
    * @export
    * @enum {string}
    */
export enum OfferSnapshotAllOfStatusEnum {
    DRAFT = 'DRAFT',
    PUBLISHED = 'PUBLISHED',
    PAUSED = 'PAUSED',
    COMPLETED = 'COMPLETED'
}
/**
    * @export
    * @enum {string}
    */
export enum OfferSnapshotAllOfGendersEnum {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    OTHER = 'OTHER'
}
/**
    * @export
    * @enum {string}
    */
export enum OfferSnapshotAllOfRepetitionEnum {
    DAY = 'DAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    CUSTOM = 'CUSTOM'
}
/**
    * @export
    * @enum {string}
    */
export enum OfferSnapshotAllOfPlaceOfVisitEnum {
    OFFLINE_STORE = 'OFFLINE_STORE',
    POS = 'POS',
    MOBILE_APP = 'MOBILE_APP'
}


